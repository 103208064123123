<script>
import StaticPageLayout from '@/templates/static/StaticPageLayout'

export default {
  metaInfo() {
    return {
      title: 'Annotations - Tools - Europe PMC',
    }
  },
  components: { StaticPageLayout },
  data() {
    return {
      title: 'Annotations',
      sections: [
        {
          id: 'how-to-use-annotations',
          heading: 'h2',
          title: 'How to use annotations',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'provide-annotations',
          heading: 'h2',
          title: 'Provide annotations',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'access-annotations-api',
          heading: 'h2',
          title: 'Access annotations API',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
    }
  },
}
</script>
<template>
  <static-page-layout id="annotations-page" :title="title" :sections="sections">
    <template slot-scope="{ section }">
      <template v-if="section.id === 'how-to-use-annotations'">
        <h3>What are they and how are they useful?</h3>
        <p>
          Annotations are biological terms or relations, such as diseases,
          chemicals or protein interactions, which can be highlighted for
          readers on abstracts and full text articles. These terms are
          identified by text mining algorithms, developed by a variety of text
          mining groups.
        </p>
        <p>
          For readers, annotations make it easier to scan an article and get a
          quick overview, find key concepts, and discover evidence, such as gene
          disease associations or molecular interactions. Annotations enable
          users to locate the primary data in the text by linking text-mined
          entities to public life sciences and chemistry databases. The goal of
          annotations is to support scientists and database curators in their
          literature research by harnessing the power of text mining, and to
          promote the contribution of text miners to the advancement of science.
        </p>

        <h3 id="what-types-of-annotations-are-available">
          What types of annotations are available?
        </h3>
        <p>Annotations are provided for:</p>
        <ul>
          <li>
            Europe PMC - Database Accessions and Resource names, Gene/Protein
            names, Organisms, Diseases, Chemicals, Gene Ontology terms and
            Experimental Methods
          </li>
          <li>
            Metagenomics (EMERALD) - Sample-Material, Body-Site, Host, State,
            Site, Place, Date, Engineered, Ecoregion, Treatment, Kit, Primer,
            Gene, LS, LCM, Sequencing
          </li>
          <li>ExTRI - Transcription factors-Gene targets</li>
          <li>IntAct - Protein–protein Interactions</li>
          <li>PubTator - Gene mutations</li>
          <li>
            PheneBank - Cells, Gene mutations, Phenotypes, Molecules, Anatomy,
            Pathways
          </li>
          <li>NaCTEM - Biological events (Phosphorylation)</li>
          <li>
            OntoGene - Cells, Cell lines, Clinical drugs, Sequences, Molecular
            processes, Organ/Tissues
          </li>
          <li>
            Open Targets Platform and DisGeNET - Gene–Disease associations
          </li>
          <li>SIB - Gene Function (GeneRIF) statements and COVoc</li>
        </ul>

        <h3 id="using-annotations">Using annotations</h3>
        <p>
          When viewing an article, a link to 'Annotations' is shown in the tools
          menu on the right, as shown below.
        </p>
        <div class="image">
          <img src="@/assets/view-key-terms.png" alt="View key terms" />
        </div>
        <p>
          Clicking or tapping on 'Annotations' opens a new panel on the right.
          Selecting the term in the panel highlights the relevant annotation in
          the text of the article on the left. Hovering over the highlight opens
          a popup window.
        </p>
        <div class="image">
          <img src="@/assets/Highlighted-term.png" alt="Highlighted term" />
        </div>
        <p>
          The pop-up window displays a link to related database record, the
          source of the annotation, and the feedback link. In the case of
          overlapping annotations in a sentence, we highlight the longest
          annotation, and the individual annotations within the phrase can be
          seen in the pop-up window.
        </p>
        <p>
          Use the 'find' and 'arrow' link to the right of the term to find the
          first, and subsequent instances of the term in the text.
        </p>
        <p>
          It is of critical importance that readers find the annotations useful.
          Readers can provide feedback on each annotation, e.g. mark incorrect
          annotations or endorse useful ones. This information is fed back to
          the Europe PMC team and will be acted upon, helping to improve the
          annotations overall. If you find an incorrect annotation, or the
          annotation is too generic and is highlighted too often, you can report
          it by clicking or tapping on the highlighted term and using the
          Feedback link in the pop-up window. You can also endorse annotations
          using the Feedback link, if they are useful to you.
        </p>
      </template>
      <template v-if="section.id === 'provide-annotations'">
        <p>
          Europe PMC is a community platform, open for contributions that
          enhance our interaction with scientific literature. The SciLite
          annotations service enables text miners to showcase their work to the
          wider public. We welcome contributions from text-mining and other
          associated communities and encourage them to share annotations on the
          SciLite annotations platform. Any text-mining group can participate by
          <a href="/AnnotationsSubmission"
            >providing their annotations in a specific format</a
          >.
        </p>

        <h3 id="getting-started">Getting started</h3>
        <p>
          If you are a text-mining group and can supply
          <a href="/AnnotationsSubmission"
            >annotations in the format we require</a
          >, please get in contact with us. Annotations may be generated on your
          own local set up, or a virtual machine on the
          <a href="//www.ebi.ac.uk/services">EBI Embassy Cloud</a>
          could also be used.
        </p>

        <h3 id="ground-rules">Ground rules</h3>
        <ul>
          <li>
            Annotations appear on all abstracts, and full text articles with a
            <a
              href="/search?query=(IN_EPMC%3Ay)%20AND%20(LICENSE%3A%22CC-BY-NC%22%20OR%20LICENSE%3A%22CC-BY%22%20OR%20LICENSE%3A%22CC0%22%20OR%20LICENSE%3A%22CC%20BY-SA%22%20OR%20LICENSE%3A%22CC%20BY-NC-SA%22)&amp;page=1"
              >CC-BY, CC-BY-NC, CC0, CC-BY-SA OR CC-BY-NC-SA license</a
            >.
          </li>
          <li>
            In the case of overlapping annotations, we highlight the maximum
            length, and the individual contributors can be seen on the pop-up
            menu.
          </li>
          <li>
            The reader is in control of which annotations they see, and can
            provide feedback if they wish.
          </li>
          <li>
            Europe PMC does not make quality judgements on the annotations: this
            is in the gift of the readers and the wider text-mining community.
          </li>
        </ul>
      </template>
      <template v-if="section.id === 'access-annotations-api'">
        <p>
          SciLite annotations data can be accessed programmatically using the
          new Annotations API.
          <a href="/AnnotationsApi">Technical documentation</a> is available.
          The API will provide annotations on all abstracts and full text
          articles with a
          <a
            href="/search?query=%28IN_EPMC:y%29+AND+%28LICENSE:%22CC-BY-NC%22+OR+LICENSE:%22CC-BY%22+OR+LICENSE:%22CC0%22+OR+OPEN_ACCESS:y%29&amp;page=1"
            >CC-BY, CC-BY-NC or CC0 license or open access</a
          >. The annotations can be provided to the user in the following
          formats:
        </p>
        <ul>
          <li>JSON</li>
          <li>XML</li>
          <li>JSON-LD</li>
          <li>ID_LIST</li>
        </ul>
        <p>
          The
          <a href="/AnnotationsApi#jsonLD">JSON-LD (JSON for Linking Data)</a>
          format is based on the
          <a href="//www.w3.org/TR/annotation-model/"
            >Web Annotation Data Model</a
          >
          recommended by the W3C. This model provides specifications that allows
          the exchange of annotations across different platforms.
        </p>
        <p>
          Join the
          <a
            href="//groups.google.com/a/ebi.ac.uk/forum/#!forum/epmc-webservices"
            >Europe PMC web service users' Google group</a
          >
          to receive notifications about annotations API.
        </p>
      </template>
    </template>
  </static-page-layout>
</template>
<style scoped lang="scss">
.image {
  margin: ($base-unit * 8) 0;
}
</style>
